import React, { useCallback, useEffect, useState } from 'react';
import {
  Application,
  Candidate,
  EProjectActivityType,
  Project,
  Timesheet,
} from '../../backend/careo-api';
import { ColumnSwitch, PlusIcon, RowSwitch } from '../../icons';
import {
  Button,
  DropdownSelectText,
  MultipleSelect,
  SearchInput,
  UploadButton,
} from '../ui';
import { WeekData, WeeksSlider } from './rotas-components/weeks-slider';
import { RotasWeekCalendar } from './rotas-components/rotas-week-calendar';
import { monthsList } from '../../constants';
import { ProjectPlacementsList } from './rotas-list';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  uploadRotasCsvRequest,
} from '../../utils';
import { toast } from 'react-toastify';

type ProjectDetailsRotasProps = {
  project: Project;
  candidates: Candidate[];
  placements: Application[];
  getPlacements: () => void;
  getProjectDetails: () => void;
};

export type RotasItem = {
  _id: string;
  day: Date;
  activityType: EProjectActivityType;
  role: string;
  status: string;
  workerName: string;
  charge: number;
  rate: number;
  placement: Application;
  timesheet: Timesheet;
};

export const generateWeeks = (year: number): WeekData[] => {
  const weeksList: WeekData[] = [];
  const startDate = new Date(year, 0, 1);

  if (startDate.getDay() !== 1) {
    startDate.setDate(startDate.getDate() - ((startDate.getDay() + 6) % 7));
  }

  let index = 1;

  while (
    startDate.getFullYear() === year ||
    (startDate.getFullYear() === year - 1 && startDate.getMonth() === 11)
  ) {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const label = `${startDate.getDate()}-${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })}`;

    weeksList.push({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      label: label,
      week: index,
      month: startDate.getMonth(),
      year: startDate.getFullYear(),
    });

    startDate.setDate(startDate.getDate() + 7); // Move to next week
    index++;
  }

  return weeksList;
};

const getCurrentWeek = (): WeekData => {
  const today = new Date();
  const firstDayOfWeek = new Date(
    today.setDate(today.getDate() - ((today.getDay() + 6) % 7)),
  );
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const pastDaysOfYear =
    (today.getTime() - firstDayOfYear.getTime()) / (1000 * 60 * 60 * 24);
  const week = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);

  return {
    startDate: firstDayOfWeek,
    endDate: lastDayOfWeek,
    label: `${firstDayOfWeek.toLocaleDateString()} - ${lastDayOfWeek.toLocaleDateString()}`,
    week,
    month: firstDayOfWeek.getMonth(),
    year: firstDayOfWeek.getFullYear(),
  };
};

const YEAR_OPTIONS = Array.from({ length: 7 }, (_, i) => {
  const year = 2020 + i;
  return { label: year.toString(), value: year };
});

export const ProjectDetailsRotas = ({
  project,
  candidates,
  placements,
  getPlacements,
  getProjectDetails,
}: ProjectDetailsRotasProps) => {
  const [isRowSwitch, setIsRowSwitch] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState<WeekData>();
  const [weeksList, setWeeksList] = useState<WeekData[]>([]);

  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth(),
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear(),
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedWeek(getCurrentWeek());
  }, []);

  const getSelectedWeek = useCallback(() => {
    return selectedWeek;
  }, [selectedWeek]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const generatedWeeks = generateWeeks(selectedYear);

    const selectedWeek = getSelectedWeek();

    setWeeksList(generatedWeeks);
    if (
      selectedWeek &&
      (selectedMonth !== currentMonth || selectedYear !== currentYear)
    ) {
      setSelectedWeek(
        generatedWeeks.find((el) => el.label === selectedWeek.label),
      );
    }

    // else {

    // }
  }, [selectedYear, selectedMonth, getCurrentWeek]);

  const [rotasList, setRotasList] = useState<RotasItem[]>([]);

  const fetchRotasList = async () => {
    //TODO: remove filter by selectedWeek when isRowSwitch is enabled
    if (!project || !placements) {
      return [];
    }

    if (!isRowSwitch && !selectedWeek) return [];

    const filteredPlacements = isRowSwitch
      ? placements
      : placements.filter((placement) => {
          const placementDate = new Date(placement.availableFrom).setHours(
            12,
            0,
            0,
            0,
          );
          const startWeek = new Date('2025-01-20').setHours(0, 0, 0, 0);
          const endWeek = new Date('2025-06-01').setHours(23, 59, 59, 999);

          return placementDate >= startWeek && placementDate <= endWeek;
        });

    const result = filteredPlacements
      .map((el) => {
        const activities = project.activities
          .map((activity) => {
            const matchingRoles = activity.roles.filter(
              (role) => role._id === el.job._id,
            );
            return {
              ...activity,
              roles: matchingRoles,
            };
          })
          .filter((activity) => activity.roles.length > 0);

        return activities.map((activity) => {
          const job = activity.roles.find((role) => role._id === el.job._id);
          return {
            _id: el._id,
            day: new Date(el.availableFrom),
            activityType: activity.activityType,
            role: `${job?.level || ''} (${job?.grade || ''})`,
            status: el.approvalStatus || 'Pending',
            workerName: `${el.candidate?.firstName || 'Unassigned'} ${el.candidate?.lastName || ''}`,
            charge: activity.charge || 0,
            rate: job?.rate || 0,
            placement: el,
          };
        });
      })
      .flat();

    const rotasList: RotasItem[] = [];

    for (const rota of result) {
      const timesheet = await AxiosInstance.timesheetMember
        .timesheetMemberControllerFindOneByPlacementId(rota.placement._id)
        .then((response) => {
          return response.data as Timesheet;
        });

      rotasList.push({
        ...rota,
        timesheet,
      });
    }

    // "679334bf7bc1a939c4e15c3f"
    setRotasList([
      ...rotasList.sort((a, b) => a.day.getTime() - b.day.getTime()),
    ]);
  };

  useEffect(() => {
    fetchRotasList();
  }, [project, placements, selectedWeek, isRowSwitch]);

  const uploadRotasCsv = async (file: File) => {
    setIsLoading(true);
    await uploadRotasCsvRequest(file, project._id)
      .then(() => {
        toast.success('Rotas uploaded successfully');

        location.reload();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="rotas-list-content" data-testid="rotas-list-content">
      <div
        className="rotas-header-container"
        data-testid="rotas-header-container"
      >
        <div
          className="rotas-header-left-container"
          data-testid="rotas-header-left"
        >
          {!isRowSwitch && (
            <>
              <DropdownSelectText
                items={monthsList}
                selectedItem={selectedMonth}
                setSelectedItem={setSelectedMonth}
                data-testid="time-period-dropdown"
              />
              <DropdownSelectText
                items={YEAR_OPTIONS}
                selectedItem={selectedYear}
                setSelectedItem={setSelectedYear}
                data-testid="time-period-dropdown"
              />
            </>
          )}
        </div>

        <div
          className="rotas-header-right-container"
          data-testid="rotas-header-right"
        >
          <UploadButton
            data-testid="upload-button"
            accept=".csv"
            onUpload={uploadRotasCsv}
          >
            Upload
          </UploadButton>

          <Button
            type="success"
            variant="outlined"
            data-testid="generate-rota-button"
            disabled
          >
            <PlusIcon /> Generate Rota with AI
          </Button>
          {isRowSwitch ? (
            <RowSwitch
              onClick={() => setIsRowSwitch(false)}
              data-testid="row-switch"
            />
          ) : (
            <ColumnSwitch
              onClick={() => setIsRowSwitch(true)}
              data-testid="column-switch"
            />
          )}
        </div>
      </div>
      <div
        className="rotas-filter-container"
        data-testid="rotas-filter-container"
      >
        <SearchInput
          placeholder="Search candidates"
          onChange={() => {}}
          data-testid="search-input"
          disabled
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-1"
          disabled
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-2"
          disabled
        />
        <MultipleSelect
          placeholder="All Status"
          options={[]}
          onChange={() => {}}
          data-testid="status-select-3"
          disabled
        />
        <MultipleSelect
          placeholder="All Tags"
          options={[]}
          onChange={() => {}}
          data-testid="tags-select"
          disabled
        />
      </div>
      {!isRowSwitch && (
        <div
          className="scrollable-weeks-container"
          data-testid="weeks-slider-container"
        >
          <WeeksSlider
            weeksList={weeksList}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            data-testid="weeks-slider"
          />
        </div>
      )}

      <div className="weeks-calendar" data-testid="weeks-calendar">
        {isRowSwitch ? (
          <>
            {selectedWeek && (
              <ProjectPlacementsList
                isLoading={false}
                rotasItems={rotasList}
                project={project}
                selectedYear={selectedYear}
              />
            )}
          </>
        ) : (
          <>
            {selectedWeek && (
              <RotasWeekCalendar
                project={project}
                selectedWeek={selectedWeek}
                data-testid="rotas-week-calendar"
                candidates={candidates}
                placements={placements}
                getPlacements={getPlacements}
                getProjectDetails={getProjectDetails}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
