import React from 'react';
import styled from 'styled-components';
import { PlusIcon } from '../../../icons';
import { Project } from '../../../backend/careo-api';
import { useConfirm } from '../../../contexts/confirm-modal.context';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatDateToYearMonthDay,
} from '../../../utils';
import { toast } from 'react-toastify';

export const EmptyRotasCardContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  padding-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      fill: #111827;
    }
  }

  &:hover {
    background-color: rgba(32, 156, 146, 0.05);
  }
`;

type EmptyRotasCardProps = {
  date: Date;
  project: Project;
  roleIndex: number;
  activityIndex: number;
  getPlacements: () => void;
};

export const EmptyRotasCard = ({
  date,
  project,
  roleIndex,
  activityIndex,
  getPlacements,
}: EmptyRotasCardProps) => {
  const { openConfirm, closeConfirm } = useConfirm();

  const onClick = () => {
    console.log({ date, json: formatDateToYearMonthDay(date) });

    openConfirm({
      title: 'Add Vacant',
      component: <>Do you want to add a new Vacant? </>,
      onConfirm: () => onAddVacant(),
    });
  };

  const onAddVacant = () => {
    AxiosInstance.projects
      .projectsControllerAddVacant(project._id, {
        placementDate: formatDateToYearMonthDay(date),
        activityIndex,
        rolesIndex: roleIndex,
      })
      .then(() => {
        toast.success('Placement added successfully');
        getPlacements();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <EmptyRotasCardContainer onClick={onClick}>
        <PlusIcon data-testid="dots-icon" />
      </EmptyRotasCardContainer>
    </>
  );
};
