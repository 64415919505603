import React, { useState } from 'react';
import { Table } from '../ui';
import {
  CheckIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  MinusIcon,
  PlusIcon,
} from '../../icons';
import { ERoute, formatDate } from '../../utils';
import {
  Compliance,
  ComplianceFile,
  EComplianceName,
  ECriminalRecordCheckType,
  LifeSupportType,
} from '../../backend/careo-api';
import { isCRMApp } from '../../environment/app.type';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplianceStatus } from './compliance-status.component';
import { downloadFile } from '../../utils';
import { ComplianceDateForm } from './compliance-date-form.component';
import { useModal } from '../../contexts/side-modal.context';

type ComplianceListProps = {
  compliancesList: Compliance[];
  getComplianceList?: () => Promise<void>;
};

const ComplianceRow = ({
  compliance,
  onClickEdit,
  id,
}: {
  compliance: Compliance;
  onClickEdit: any;
  id: string;
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const subItems: {
    label: string;
    value: ComplianceFile[] | boolean | undefined;
  }[] = (() => {
    switch (compliance.taskName) {
      case EComplianceName.RegistrationDocuments:
        return [
          {
            label: 'Application form',
            value: compliance.metadata?.applicationFormFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Company Handbook',
            value: compliance.metadata?.handbookFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Interview Form',
            value: compliance.metadata?.interviewFormFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Appraisals',
            value: compliance.metadata?.appraisalsFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Emergency Contact',
            value:
              !!compliance.metadata?.emergencyName &&
              !!compliance.metadata?.emergencyPhoneNumber &&
              !!compliance.metadata?.emergencyRelationship,
          },
        ];
      case EComplianceName.EmploymentHistoryReferences:
        return [
          {
            label: 'CV',
            value: compliance.metadata?.cvFile?.filter((el) => !el.archiveDate),
          },
          {
            label: 'References',
            value: compliance.metadata?.references?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];
      case EComplianceName.RightToWork:
        return [
          {
            label: 'Certificate',
            value: compliance.metadata?.certificateFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Visa',
            value: compliance.metadata?.visaFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];
      case EComplianceName.CriminalRecordCheck:
        return [
          {
            label: 'DBS Certificate',
            value:
              (compliance.metadata?.criminalRecordType ===
                ECriminalRecordCheckType.Dbs &&
                compliance.metadata?.dbsCertificateFile?.filter(
                  (el) => !el.archiveDate,
                )) ||
              (compliance.metadata?.criminalRecordType ===
                ECriminalRecordCheckType.Ni &&
                compliance.metadata?.niCertificateFile?.filter(
                  (el) => !el.archiveDate,
                )) ||
              (compliance.metadata?.criminalRecordType ===
                ECriminalRecordCheckType.Pvg &&
                compliance.metadata?.pvgCertificateFile?.filter(
                  (el) => !el.archiveDate,
                )) ||
              (compliance.metadata?.criminalRecordType ===
                ECriminalRecordCheckType.International &&
                compliance.metadata?.internationalCertificateFile?.filter(
                  (el) => !el.archiveDate,
                )),
          },
          {
            label: 'Expiry Date',
            value:
              !!compliance.metadata?.dbsTimePeriod &&
              !!compliance.metadata?.dbsCustomDate,
          },
        ];
      case EComplianceName.IDBadge:
        return [
          {
            label: 'Profile Picture',
            value: compliance.metadata?.profilePictureFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'ID badge',
            value: compliance.metadata?.badgeFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];
      case EComplianceName.ProfessionalRegistration:
        return [
          {
            label: 'Registration Certificates',
            value: compliance.metadata?.registrationCertificates?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Registration Checks Files',
            value: compliance.metadata?.registrationChecksFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];
      case EComplianceName.OccupationalHealthClearance:
        return [
          {
            label: 'Fitness to work certificate',
            value: compliance.metadata?.fitnessToWorkCertificateFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Bloods',
            value: compliance.metadata?.bloodsFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Medical Questionnaire',
            value: compliance.metadata?.medicalQuestionnaireFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];
      case EComplianceName.MandatoryTraining:
        return [
          {
            label: 'Training Certificate',
            value: compliance.metadata?.trainingCertificateFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Coverage Certificate',
            value: compliance.metadata?.coverageCertificateFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];
      case EComplianceName.IELTSOET:
        return [
          {
            label: 'IELTS|OET Certificate',
            value: compliance.metadata?.ieltsCertificateFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Expiry Date',
            value: !!compliance.metadata?.ieltsCertificateExpiryDate,
          },
        ];
      case EComplianceName.IndemnityInsurance:
        return [
          {
            label: 'Indemnity Insurance',
            value: compliance.metadata?.indemnityInsuranceFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Expiry Date',
            value: !!compliance.metadata?.indemnityInsuranceExpiryDate,
          },
        ];
      case EComplianceName.LifeSupport:
        return [
          {
            label: 'Training Certificate',
            value: compliance.metadata?.trainingCertificateFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Coverage Certificate',
            value: compliance.metadata?.coverageCertificateFile?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];
      case EComplianceName.LifeSupport:
        return [
          {
            label: 'Life Support Certificate',
            value:
              (compliance.metadata?.lifeSupportType === LifeSupportType.Als &&
                compliance.metadata?.alsCertificateFiles?.filter(
                  (el) => !el.archiveDate,
                )) ||
              (compliance.metadata?.lifeSupportType === LifeSupportType.Bls &&
                compliance.metadata?.blsCertificateFiles?.filter(
                  (el) => !el.archiveDate,
                )) ||
              (compliance.metadata?.lifeSupportType === LifeSupportType.Ils &&
                compliance.metadata?.ilsCertificateFiles?.filter(
                  (el) => !el.archiveDate,
                )),
          },
          {
            label: 'Expiry Date',
            value: !!compliance.metadata?.lifeSupportExpiryDate,
          },
        ];
      case EComplianceName.PrimaryMedicalQualification:
        return [
          {
            label: 'Diplomas',
            value: compliance.metadata?.universityDiplomasFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Expiry Date',
            value: !!compliance.metadata?.universityDiplomasExpiryDate,
          },
        ];
      case EComplianceName.IdentityInformation:
        return [
          {
            label: 'Passport',
            value: compliance.metadata?.passportFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Driving License',
            value: compliance.metadata?.drivingLicenseFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
          {
            label: 'Proof of Address',
            value: compliance.metadata?.proofOfAddressFiles?.filter(
              (el) => !el.archiveDate,
            ),
          },
        ];

      default:
        return [];
    }
  })();

  return (
    <>
      <tr key={compliance._id} data-testid={`compliance-row-${compliance._id}`}>
        <td onClick={() => setIsOpen((prev) => !prev)} className="toggle-td">
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </td>
        <td>
          <div
            className={`task-container`}
            data-testid={`task-container-${compliance._id}`}
          >
            <label
              htmlFor={`task-checkbox-${compliance._id}`}
              data-testid={`task-label-${compliance._id}`}
            >
              {compliance.taskName}
            </label>
          </div>
        </td>
        <td data-testid={`reminder-date-${compliance._id}`}>
          {compliance.reminderDate ? formatDate(compliance.reminderDate) : '-'}
        </td>
        <td data-testid={`expiry-date-${compliance._id}`}>
          {compliance.expiryDate ? formatDate(compliance.expiryDate) : '-'}
        </td>
        <td>
          <ComplianceStatus
            value={compliance.status}
            data-testid={`compliance-status-${compliance._id}`}
          />
        </td>
        <td>
          <div className="action-item">
            {!isCRMApp && (
              <>
                <div
                  className="edit-icon"
                  onClick={() => onClickEdit(compliance)}
                  data-testid={`edit-icon-${compliance._id}`}
                >
                  <EditIcon />
                </div>
                <div
                  className="view-icon"
                  onClick={() =>
                    navigate(
                      `/${ERoute.CANDIDATES}/${id}/compliance/${compliance.taskName}`,
                    )
                  }
                  data-testid={`view-icon-${compliance._id}`}
                >
                  <EyeIcon />
                </div>
              </>
            )}
            {/* <div
              className="delete-icon disabled"
              data-testid={`delete-icon-${compliance._id}`}
            >
              <DeleteIcon />
            </div> */}
          </div>
        </td>
      </tr>
      {isOpen && (
        <>
          {subItems.map((el, index) => {
            const isBooleanValue = typeof el.value === 'boolean';
            const activeFiles = Array.isArray(el.value) ? el.value : [];

            const downloadFiles = () => {
              activeFiles.map((el) => downloadFile(el.file));
            };

            return (
              <tr
                data-testid={`compliance-row-sub-item-${compliance._id}-${index}`}
              >
                <td></td>
                <td>
                  <div className={`task-container`}>
                    <label>{el.label}</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td className="check-icon">
                  {isBooleanValue || !!activeFiles.length ? <CheckIcon /> : '-'}
                </td>
                <td>
                  <div className="action-item">
                    {!isCRMApp && !!activeFiles.length && (
                      <>
                        <div
                          className="download-icon"
                          onClick={() => downloadFiles()}
                        >
                          <DownloadIcon />
                        </div>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};

export const ComplianceList = ({
  compliancesList,
  getComplianceList,
}: ComplianceListProps) => {
  const { id } = useParams();

  const { openModal, closeModal } = useModal();

  const onClickEdit = (compliance: Compliance) => {
    openModal({
      title: 'Update Date',
      component: (
        <ComplianceDateForm
          compliance={compliance}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getComplianceList?.();
            closeModal();
          }}
          data-testid="compliance-date-form"
        />
      ),
    });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Task</th>
            <th>Reminder Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th className="action-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {compliancesList.length > 0 ? (
            <>
              {compliancesList.map((el) => {
                return (
                  <ComplianceRow
                    compliance={el}
                    id={id!}
                    onClickEdit={onClickEdit}
                  />
                );
              })}
            </>
          ) : (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="no-item-found"
              >
                No item found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
