import React from 'react';
import styled from 'styled-components';
import { WeekData } from './weeks-slider';
import { Button } from '../../ui';
import { DotsIcon, PlusIcon } from '../../../icons';
import { RotasCard } from './rotas-card';
import {
  Activity,
  Application,
  Candidate,
  Project,
} from '../../../backend/careo-api';
import { EmptyRotasCard } from './empty-rotas-card';
import { useModal } from '../../../contexts/side-modal.context';
import { AddActivityForm } from '../add-activity-form';
import { AddRoleActivityForm } from '../add-role-activity-form';
import { breakpoint } from '../../../constants';
import { formatDateToYearMonthDay } from '../../../utils';

const RotasWeekCalendarContainer = styled.div`
  font-family: Arial, sans-serif;
  overflow-x: auto;

  .schedule-table {
    width: 100%;
    border-collapse: collapse;

    thead th.day-item {
      border-image: linear-gradient(
          transparent 10%,
          #e0e0ed 10% 75%,
          transparent 50%
        )
        0 0 0 1 / 1px;
    }

    tbody tr.data-row {
      border-left: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      td {
        border-right: 1px solid #e0e0e0;
      }
    }

    th {
      &.today {
        background-color: #f5fbf6;
      }
    }

    td,
    th {
      width: 340px;
    }

    .days-row,
    .theatre-row,
    .data-row {
      display: flex;
      .new-activity,
      .theatre-title,
      .role-cell {
        font-size: 14px;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;

        &.add-new-role {
          cursor: pointer;
          &:hover {
            background-color: #f5fbf6;
          }
        }
      }
    }

    .days-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .day-divider {
        border-radius: 1px;
        background: #e5e8eb;
        width: 1px;
        height: 40px;
      }

      th,
      td {
        font-weight: bold;
        text-align: center;
        flex: 1;
        height: 100%;
        padding: 12px;

        &.new-activity {
          display: flex;
          align-items: center;
          gap: 15px;
          justify-content: space-between;
          .project-name {
            font-size: 14px;
          }
          > .button {
            padding: 5px 12px;
            gap: 5px;
          }
        }

        .day-number {
          font-size: 18px;
          font-weight: 500;
        }
        .day-name {
          color: #9d9ca3;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .theatre-row {
      background-color: #f5fbf6;
      .theatre-title {
        background-color: #f5fbf6;
        font-size: 12px;
        padding: 6px 20px;
        font-weight: 600;
      }
      .theatre-empty-cell {
      }
    }

    .data-row {
      .role-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg path {
          fill: #a0aec0;
        }
      }
      .day-cell {
        padding: 3px;
      }
    }

    .header-row,
    .row {
    }
    .day-cell,
    .role-cell {
      padding: 16px;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .schedule-table {
      thead th.day-item {
      }

      tbody tr.data-row {
        td {
        }
      }

      th {
        &.today {
        }
      }

      td,
      th {
        width: 200px;
      }

      .days-row,
      .theatre-row,
      .data-row {
        .new-activity,
        .theatre-title,
        .role-cell {
          font-size: 10px;
          &.add-new-role {
            &:hover {
            }
          }
        }
      }

      .days-row {
        .day-divider {
          width: 1px;
          height: 40px;
        }

        th,
        td {
          padding: 8px;

          &.new-activity {
            gap: 8px;
            .project-name {
              font-size: 9px;
            }
            > .button {
              padding: 4px 8px;
            }
          }

          .day-number {
            font-size: 12px;
          }
          .day-name {
            font-size: 8px;
          }
        }
      }
      .theatre-row {
        .theatre-title {
          font-size: 8px;
          padding: 4px 12px;
        }
        .theatre-empty-cell {
        }
      }

      .data-row {
        .role-cell {
          svg path {
          }
        }
        .day-cell {
          padding: 3px;
        }
      }

      .header-row,
      .row {
      }
      .day-cell,
      .role-cell {
        padding: 10px;
      }
    }
  }
`;

type RotasWeekCalendarProps = {
  selectedWeek: WeekData;
  project: Project;
  candidates: Candidate[];
  placements: Application[];
  getPlacements: () => void;
  getProjectDetails: () => void;
};

export const RotasWeekCalendar = ({
  selectedWeek,
  project,
  candidates,
  placements,
  getPlacements,
  getProjectDetails,
}: RotasWeekCalendarProps) => {
  // Generate the days of the week based on the selected week's starting day
  const { openModal } = useModal();

  const daysOfTheWeek = Array.from({ length: 7 }, (_, i) => {
    const day = new Date(selectedWeek.startDate);
    day.setDate(day.getDate() + i);
    const isToday = day.toDateString() === new Date().toDateString();
    const dayName = day.toLocaleDateString('en-GB', { weekday: 'short' });
    const dayNumber = day.getDate();
    return { day, dayName, dayNumber, isToday };
  });

  const addNewActivity = () => {
    openModal({
      title: 'Add New Activity',
      component: (
        <AddActivityForm
          selectedProject={project}
          getProjectDetails={getProjectDetails}
        />
      ),
    });
  };

  const addNewRoleActivity = (activity: Activity) => {
    openModal({
      title: 'Add New Role',
      component: (
        <AddRoleActivityForm
          selectedProject={project}
          selectedActivity={activity}
          getProjectDetails={getProjectDetails}
        />
      ),
    });
  };

  return (
    <RotasWeekCalendarContainer
      className="rotas-week-calendar"
      data-testid="rotas-week-calendar"
    >
      <table className="schedule-table" data-testid="schedule-table">
        <thead>
          <tr className="days-row" data-testid="days-row">
            <th className="new-activity" data-testid="new-activity">
              <div className="project-name" data-testid="project-name">
                {project.client.clientName} {project.specialty}
              </div>
              <Button
                type="success"
                variant="outlined"
                data-testid="add-activity-button"
                onClick={() => addNewActivity()}
              >
                <PlusIcon /> Add Activity
              </Button>
            </th>
            {daysOfTheWeek.map((el, index) => {
              return (
                <th
                  className={`day-item ${el.isToday ? 'today' : ''}`}
                  key={index}
                  data-testid={`day-item-${index}`}
                >
                  <div
                    className="day-number"
                    data-testid={`day-number-${index}`}
                  >
                    {el.dayNumber}
                  </div>
                  <div className="day-name" data-testid={`day-name-${index}`}>
                    {el.dayName}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {project.activities.map((activity, activityIndex) => (
            <>
              <tr className="theatre-row" data-testid="theatre-row">
                <td className="theatre-title" data-testid="theatre-title">
                  {activity.subSpecialty} - {activity.activityType}
                </td>
              </tr>
              {activity.roles.map((role, roleIndex) => (
                <tr
                  key={roleIndex}
                  className="data-row"
                  data-testid={`data-row-${roleIndex}`}
                >
                  <td
                    className="role-cell"
                    data-testid={`role-cell-${roleIndex}`}
                  >
                    <div
                      className="role-name"
                      data-testid={`role-name-${roleIndex}`}
                    >
                      {role.level} ({role.grade})
                    </div>
                    <div
                      className="role-action"
                      data-testid={`role-action-${roleIndex}`}
                    >
                      <DotsIcon />
                    </div>
                  </td>
                  {daysOfTheWeek.map((dateItem, dayIndex) => {
                    const item: Application | undefined = placements.find(
                      (el) =>
                        el.job._id === role?._id &&
                        formatDateToYearMonthDay(new Date(el.availableFrom)) ===
                          formatDateToYearMonthDay(dateItem.day),
                    );

                    return (
                      <td
                        key={dayIndex}
                        className="day-cell"
                        data-testid={`day-cell-${roleIndex}-${dayIndex}`}
                      >
                        {item ? (
                          <RotasCard
                            key={`${activity._id}-${item._id}`}
                            data-testid={`rotas-card-${roleIndex}-${dayIndex}`}
                            application={item}
                            date={dateItem.day}
                            role={role}
                            activity={activity}
                            project={project}
                            getPlacements={getPlacements}
                          />
                        ) : (
                          <EmptyRotasCard
                            data-testid={`rotas-card-${roleIndex}-${dayIndex}`}
                            date={dateItem.day}
                            project={project}
                            roleIndex={roleIndex}
                            activityIndex={activityIndex}
                            getPlacements={getPlacements}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
              <tr className="data-row" data-testid={`data-row`}>
                <td
                  className="role-cell add-new-role"
                  data-testid={`role-cell`}
                  onClick={() => addNewRoleActivity(activity)}
                >
                  Add New Role <PlusIcon />
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </RotasWeekCalendarContainer>
  );
};
