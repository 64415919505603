import React, { useEffect, useMemo, useState } from 'react';
import { Button, CardContainer, Pagination, Table, TitlePage } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { CampaignTemplate } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  getItemsOfPage,
  getNumberOfPages,
} from '../../utils';
import { toast } from 'react-toastify';
import {
  NewCampaignTemplateForm,
  UpdateCampaignTemplateForm,
} from '../campaign';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';

const itemsPerPage = 8;

export const CampaignTemplates = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [campaignTemplatesList, setCampaignTemplates] = useState<
    CampaignTemplate[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const { openConfirm, closeConfirm } = useConfirm();
  const onDeleteConfirm = (item: CampaignTemplate) => {
    openConfirm({
      title: 'Delete Template',
      component: (
        <>
          Do you want to delete <b>{item.name}</b>
        </>
      ),
      onConfirm: () => deleteCampaignTemplate(item),
    });
  };

  const { openModal, closeModal } = useModal();
  const onClickCreate = () => {
    openModal({
      title: 'New Template',
      component: (
        <NewCampaignTemplateForm
          onCancel={() => closeModal()}
          getCampaignTemplates={getCampaignTemplates}
        />
      ),
    });
  };

  const onClickEdit = (template: CampaignTemplate) => {
    openModal({
      title: 'Update Template',
      component: (
        <UpdateCampaignTemplateForm
          campaignTemplate={template}
          onCancel={() => closeModal()}
          getCampaignTemplates={getCampaignTemplates}
        />
      ),
    });
  };

  const deleteCampaignTemplate = async (recordToDelete: CampaignTemplate) => {
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerDelete(recordToDelete._id)
      .then((response) => {
        toast.success('Campaign Template deleted successfully');
        getCampaignTemplates();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    closeConfirm();
  };

  const getCampaignTemplates = async () => {
    setIsLoading(true);
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setCampaignTemplates(result);
        setTotalPages(getNumberOfPages(result.length, itemsPerPage));
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(campaignTemplatesList, currentPage, itemsPerPage);
  }, [campaignTemplatesList, currentPage]);

  useEffect(() => {
    getCampaignTemplates();
  }, []);

  return (
    <>
      <CardContainer className="right-container" data-testid="card-container">
        <div
          className="settings-title-content"
          data-testid="settings-title-content"
        >
          <TitlePage>Campaign Templates</TitlePage>
          <Button
            type="primary"
            onClick={() => onClickCreate()}
            data-testid="add-new-button"
          >
            <PlusIcon />
            Add New
          </Button>
        </div>
        <hr />
        <div className="settings-content" data-testid="settings-content">
          <div
            className="data-table-container"
            data-testid="data-table-container"
          >
            <Table data-testid="campaign-templates-table">
              <thead>
                <tr>
                  <th>
                    <div>
                      <label data-testid="template-name-header">
                        Template Name
                      </label>
                      <SortIcon data-testid="sort-template-name" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="message-header">Message</label>
                      <SortIcon data-testid="sort-message" />
                    </div>
                  </th>
                  <th className="action-th">
                    <div data-testid="action-header">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="loading-row"
                    >
                      Loading ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {paginatedData.length > 0 ? (
                      <>
                        {paginatedData.map((template) => (
                          <tr
                            key={template._id}
                            data-testid={`campaign-row-${template._id}`}
                          >
                            <td data-testid={`campaign-name-${template._id}`}>
                              {template.name}
                            </td>
                            <td
                              data-testid={`campaign-message-${template._id}`}
                            >
                              {template.message}
                            </td>
                            <td>
                              <div
                                className="action-item"
                                data-testid={`action-item-${template._id}`}
                              >
                                <div
                                  className="edit-icon"
                                  onClick={() => onClickEdit(template)}
                                  data-testid={`edit-icon-${template._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() => onDeleteConfirm(template)}
                                  data-testid={`delete-icon-${template._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={100}
                          className="text-center"
                          data-testid="no-item-row"
                        >
                          No item found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
          <Pagination
            data-testid="pagination"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            totalEntries={campaignTemplatesList.length}
          />
        </div>
      </CardContainer>
    </>
  );
};
