import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  CardContainer,
  Button,
  EmailContent,
  EmailCreate,
  EmailsTitleList,
  EmailsTypeList,
} from '../../components';
import { EmailsPageContainer } from './emails.style';
import {
  UserEmailsResponseDto,
  BOX_TYPE,
  EmailBody,
  EmailProviders,
} from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { GmailIcon, OutlookIcon, PlusIcon } from '../../icons';
import { useAuth } from '../../contexts/auth.context';

export enum EmailSender {
  Candidate = 'candidate',
  Client = 'client',
  User = 'user',
}

export interface EmailBodyUi extends EmailBody {
  isDraft: boolean;
  isSent: boolean;
}

export interface EmailsEmailUi extends UserEmailsResponseDto {
  emails: EmailBodyUi[];
}

export const EmailsPage = () => {
  const { user } = useAuth();
  const { emailId, emailType } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const [isCreateEmailOpen, setIsCreateEmailOpen] = useState(false);

  const clickLinkEmailProvider = (provider: EmailProviders): any => {
    AxiosInstance.emails
      .emailsControllerGetAuthUrl({
        portal: process.env.REACT_APP_NAME!,
        provider,
      })
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error?.message ?? 'Something went wrong');
      });
  };

  useEffect(() => {
    // Check if emailType is a valid BOX_TYPE
    if (!Object.values(BOX_TYPE).includes(emailType as BOX_TYPE)) {
      navigate(`/emails/${BOX_TYPE.INBOX}`);
    }

    const error = queryParams.get('error');

    if (error !== null)
      switch (error) {
        case 'ERROR_LINK_WITH_CONNECTED_EMAIL':
          toast.error(
            <div style={{ fontSize: '13px' }}>
              You should link with same email as {user?.email}
            </div>,
          );
          break;

        case 'USER_MUST_ACCEPT_ALL_PERMISSIONS':
          toast.error(
            <div style={{ fontSize: '13px' }}>
              You should accept all permissions in the consent screen
            </div>,
          );
          break;

        case 'NO_LICENSE_ATTACHED_TO_USER_EMAIL':
          toast.error(
            <div style={{ fontSize: '13px' }}>
              Contact your organization Admin and request for a Microsoft{' '}
              <a
                style={{ color: 'black', textDecoration: 'underline' }}
                href="https://www.microsoft.com/en-us/microsoft-365/exchange/compare-microsoft-exchange-online-plans"
                target="_blank"
                rel="noreferrer"
              >
                Exchange Online license
              </a>{' '}
              to be attached to your Email
            </div>,
          );
          break;

        default:
          toast.error(
            <div style={{ fontSize: '13px' }}>
              Failed to link your email account, try again or contact Admin
            </div>,
          );
          break;
      }
  }, []);

  if (!Object.values(BOX_TYPE).includes(emailType as BOX_TYPE)) {
    return <></>;
  }

  //  #################### EMAILS ####################

  if (!user?.emailLinked) {
    return (
      <EmailsPageContainer>
        <CardContainer className={'email-not-linked blurred-background'}>
          <h3> You email is not linked yet </h3>
          <div className="email-linking-btn-container">
            <div onClick={() => clickLinkEmailProvider(EmailProviders.GMAIL)}>
              <GmailIcon />
              Link With Your Gmail Account
            </div>
            <div onClick={() => clickLinkEmailProvider(EmailProviders.OUTLOOK)}>
              <OutlookIcon />
              Link With You Outlook Account
            </div>
          </div>
        </CardContainer>
      </EmailsPageContainer>
    );
  }

  return (
    <EmailsPageContainer>
      <div className="emails-header">
        <div className="left-filter"></div>
        <div className="right-filter">
          {/* <SearchInput
            placeholder={'Search'}
            onChange={(e) => setSearch(e.target.value ?? '')}
          /> */}
          <Button
            type="primary"
            onClick={() => setIsCreateEmailOpen(true)}
            data-testid="add-new-email"
          >
            <PlusIcon /> New email
          </Button>
        </div>
      </div>
      <CardContainer className="emails-container">
        <div className="left-container">
          <EmailsTypeList />
        </div>
        <div className="right-container">
          {isCreateEmailOpen ? (
            <EmailCreate
              onCancel={() => {
                setIsCreateEmailOpen(false);
              }}
              data={undefined}
            />
          ) : emailId ? (
            <EmailContent />
          ) : (
            <EmailsTitleList />
          )}
        </div>
      </CardContainer>
    </EmailsPageContainer>
  );
};
