import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  HeaderPageContainer,
  NewPayrollForm,
  Pagination,
  SearchInput,
  Select,
  SubTitlePage,
  Table,
  TitlePage,
  UpdatePayrollForm,
  UploadButton,
} from '../../../components';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PlusIcon,
  SortIcon,
} from '../../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterPayroll,
  filterPayrolls,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortPayrolls,
  uploadPayrollsCsvRequest,
} from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EUserRole, Payroll, User } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';
import { useModal } from '../../../contexts/side-modal.context';
import { useConfirm } from '../../../contexts/confirm-modal.context';

const itemsPerPage = 8;

export const PayrollsListPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isProfileCompleteQuery = searchParams.get('isProfileComplete');
  const [usersList, setUsersList] = useState<User[]>([]);

  const { user } = useAuth();

  const [payrolls, setPayrolls] = useState<Payroll[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [filter, setFilter] = useState<TFilterPayroll>({
    search: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const { openConfirm, closeConfirm } = useConfirm();

  const onDeleteConfirm = (item: Payroll) => {
    openConfirm({
      title: 'Delete Payroll',
      component: (
        <>
          Do you want to delete <b>{item?.payrollProviderName}</b>
        </>
      ),
      onConfirm: () => deletePayroll(item),
    });
  };

  const { openModal, closeModal } = useModal();
  const onClickCreate = () => {
    openModal({
      title: 'New Payroll',
      component: (
        <NewPayrollForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getPayrolls();
            closeModal();
          }}
          usersList={usersList}
          data-testid="new-payroll-form"
        />
      ),
    });
  };

  const onClickEdit = (payroll: Payroll) => {
    openModal({
      title: 'Update Payroll',
      component: (
        <UpdatePayrollForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getPayrolls();
            closeModal();
          }}
          selectedPayroll={payroll}
          data-testid="update-payroll-form"
        />
      ),
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.PAYROLLS}/${id}`);
  };

  const uploadPayrollsCsv = async (file: File) => {
    await uploadPayrollsCsvRequest(file)
      .then(() => {
        getPayrolls();
        toast.success('Payrolls uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getPayrolls = async () => {
    setIsLoading(true);
    await AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        let result = response.data.items;
        if (isProfileCompleteQuery === 'false') {
          result = result.filter((el) => !el.isProfileComplete);
        }
        setPayrolls(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deletePayroll = async (payroll: Payroll) => {
    await AxiosInstance.payrolls
      .payrollsControllerDelete(payroll?._id!)
      .then(() => {
        toast.success('Payroll Removed successfully');
        getPayrolls();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(payrollsList, currentPage, itemsPerPage);
  }, [payrollsList, currentPage]);

  useEffect(() => {
    const filteredData = filterPayrolls(payrolls, filter);
    const sortedData = sortPayrolls(filteredData, sort);
    setPayrollsList(sortedData);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [payrolls, filter, sort]);

  useEffect(() => {
    getPayrolls();
    getUsers();
  }, []);

  return (
    <>
      <CardContainer data-testid="payrolls-card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="payrolls-title">Payrolls</TitlePage>
            <SubTitlePage data-testid="payrolls-subtitle">
              Manage your Payroll Providers
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <UploadButton
              accept=".csv"
              onUpload={uploadPayrollsCsv}
              data-testid="upload-button"
            >
              Upload
            </UploadButton>
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="add-new-button"
            >
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search payroll"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          <Select
            options={[]}
            disabled
            placeholder="All Offices"
            data-testid="offices-select"
          />
          <Select
            options={[]}
            disabled
            placeholder="All Job Titles"
            data-testid="job-titles-select"
          />
          <Select
            options={[]}
            disabled
            placeholder="All Status"
            data-testid="status-select"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="payrolls-table">
            <thead>
              <tr>
                {/* <th className="checkbox-table" data-testid="checkbox-header">
                  <input type="checkbox" data-testid="select-all-checkbox" />
                </th> */}
                <th
                  onClick={() => onSelectSort('payrollProviderName', setSort)}
                  data-testid="payroll-provider-name-header"
                >
                  <div>
                    <label>Payroll Provider Name</label>
                    <SortIcon
                      value={
                        sort.key === 'payrollProviderName' ? sort.value : ''
                      }
                      data-testid="payroll-provider-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('leadContactFirstName', setSort)}
                  data-testid="lead-contact-header"
                >
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={
                        sort.key === 'leadContactFirstName' ? sort.value : ''
                      }
                      data-testid="lead-contact-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user-firstName', setSort)}
                  data-testid="line-manager-header"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                      data-testid="line-manager-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() =>
                    onSelectSort('leadContactWorkPhoneNumber', setSort)
                  }
                  data-testid="phone-header"
                >
                  <div>
                    <label>Phone</label>
                    <SortIcon
                      value={
                        sort.key === 'leadContactWorkPhoneNumber'
                          ? sort.value
                          : ''
                      }
                      data-testid="phone-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('vatNumber', setSort)}
                  data-testid="vat-number-header"
                >
                  <div>
                    <label>VAT Number</label>
                    <SortIcon
                      value={sort.key === 'vatNumber' ? sort.value : ''}
                      data-testid="vat-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('isProfileComplete', setSort)}
                  data-testid="profile-header"
                >
                  <div>
                    <label>Profile</label>
                    <SortIcon
                      value={sort.key === 'isProfileComplete' ? sort.value : ''}
                      data-testid="profile-sort-icon"
                    />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-row"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((payroll) => {
                        const isPayrollBelongsToUser =
                          payroll?.user?._id === user?._id ||
                          user?.role === EUserRole.Admin;

                        return (
                          <tr
                            key={payroll._id}
                            data-testid={`payroll-row-${payroll._id}`}
                          >
                            {/* <td className="checkbox-table">
                              <input
                                type="checkbox"
                                data-testid={`checkbox-${payroll._id}`}
                              />
                            </td> */}
                            <td data-testid={`payroll-name-${payroll._id}`}>
                              {payroll.payrollProviderName}
                            </td>
                            <td data-testid={`lead-contact-${payroll._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {payroll.leadContactFirstName}{' '}
                                    {payroll.leadContactLastName}
                                  </div>
                                  <div className="email">
                                    {payroll.leadContactEmail}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`line-manager-${payroll._id}`}>
                              {`${payroll.user?.firstName} ${payroll.user?.lastName}`}
                            </td>
                            <td data-testid={`phone-${payroll._id}`}>
                              {payroll.leadContactWorkPhoneNumber || '-'}
                            </td>
                            <td data-testid={`vat-number-${payroll._id}`}>
                              {payroll.vatNumber || '-'}
                            </td>
                            <td data-testid={`profile-status-${payroll._id}`}>
                              <Badge
                                type={
                                  payroll.isProfileComplete
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {payroll.isProfileComplete
                                  ? 'Complete'
                                  : 'Incomplete'}
                              </Badge>
                            </td>
                            <td>
                              <div className="action-item">
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(payroll._id)}
                                  data-testid={`view-icon-${payroll._id}`}
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className={`edit-icon ${
                                    !isPayrollBelongsToUser && 'disabled'
                                  }`}
                                  onClick={() =>
                                    isPayrollBelongsToUser &&
                                    onClickEdit(payroll)
                                  }
                                  data-testid={`edit-icon-${payroll._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className={`delete-icon ${
                                    !isPayrollBelongsToUser && 'disabled'
                                  }`}
                                  onClick={() =>
                                    isPayrollBelongsToUser &&
                                    onDeleteConfirm(payroll)
                                  }
                                  data-testid={`delete-icon-${payroll._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-found"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={payrollsList.length}
          data-testid="pagination-component"
        />
      </CardContainer>
    </>
  );
};
