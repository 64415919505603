import React, { useEffect, useMemo, useState } from 'react';
import {
  ATag,
  Button,
  FormContainer,
  ProgressBar,
  TabPageContainer,
  TitlePage,
} from '../../ui';
import { Client, Lead, Trust, User } from '../../../backend/careo-api';
import { GeneralFormStep } from './general-form-step';
import { LeadFinancialFormStep } from './lead-financial-form-step';
import { LegalFormStep } from './legal-form-step';
import { PlusIcon } from '../../../icons';
import { AxiosInstance, ERoute } from '../../../utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
type LeadFormProps = {
  selectedLead?: Lead;
  usersList: User[];
  trustsList: Trust[];
  clientsList: Client[];
  getLeads: () => void;
};
const requiredFields: Array<keyof Lead> = [
  '_id',
  'status',
  'type',
  'cpm',
  'region',
  'trust',
  'specialty',
  'hospital',
  'durationValue',
  'durationType',
  'revenue',
  'cost',
  'legalContact',
];

export const calculateProgressLead = (lead: Lead) => {
  // Count filled fields
  const filledFields = requiredFields.filter(
    (field) => lead[field] !== undefined && lead[field] !== null,
  ).length;

  // Calculate progress as a percentage
  return (filledFields / requiredFields.length) * 100;
};

export const LeadForm = ({
  selectedLead,
  usersList,
  trustsList,
  clientsList,
  getLeads,
}: LeadFormProps) => {
  const [step, setStep] = useState(1);
  const [createdLead, setCreatedLead] = useState<Lead>();
  const navigate = useNavigate();

  const calculateProgress = useMemo(() => {
    if (!createdLead) return 0;

    return calculateProgressLead(createdLead);
  }, [createdLead]);

  useEffect(() => {
    if (selectedLead) {
      setCreatedLead(selectedLead);
    }
  }, [selectedLead]);

  const createProject = async (lead: Lead) => {
    await AxiosInstance.leads
      .leadsControllerCreateProjectViaLead({ leadId: lead._id })
      .then((result) => {
        const project = result.data;
        toast.success('General Project saved successfully');
        navigate(`/${ERoute.PROJECTS}/${project._id}`);
      })
      .catch((e) => {
        toast.error(
          'Could not create a project for this lead, please try again or contact Admin',
        );
      });
  };

  return (
    <FormContainer data-testid="form-container">
      <div className="side-modal-header-container">
        <TitlePage className="side-modal-title">
          {!selectedLead ? 'New Opportunity' : 'Update Lead'}
        </TitlePage>
        {createdLead && !createdLead.project && calculateProgress === 100 && (
          <Button
            onClick={() => createProject(createdLead)}
            type="success"
            variant="outlined"
            data-testid="create-project-lead"
          >
            <PlusIcon /> Create Project from Lead
          </Button>
        )}
      </div>
      <ProgressBar value={calculateProgress} data-testid="progress-bar" />
      <TabPageContainer
        className="form-tab-container"
        data-testid="tab-page-container"
      >
        <div className="tab-items-list">
          <ATag
            className={`tab-item text-capitalize ${step === 1 ? 'active' : ''}`}
            onClick={() => setStep(1)}
            data-testid="general-tab"
          >
            General
          </ATag>
          <ATag
            className={`tab-item text-capitalize ${step === 2 ? 'active' : ''} ${!createdLead?._id && 'disabled'}`}
            onClick={() => createdLead?._id && setStep(2)}
            data-testid="financial-tab"
          >
            Financial
          </ATag>
          <ATag
            className={`tab-item text-capitalize ${step === 3 ? 'active' : ''} ${!createdLead?.revenue && 'disabled'}`}
            onClick={() => createdLead?.revenue && setStep(3)}
            data-testid="legal-tab"
          >
            Legal
          </ATag>
        </div>
      </TabPageContainer>

      {/* Render the form fields based on the current step */}
      {step === 1 && (
        <GeneralFormStep
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          setStep={setStep}
          createdLead={createdLead}
          setCreatedLead={setCreatedLead}
          data-testid="general-form-step"
          getLeads={getLeads}
          isStepper={true}
        />
      )}

      {step === 2 && createdLead && (
        <LeadFinancialFormStep
          step={step}
          setStep={setStep}
          createdLead={createdLead}
          setLead={setCreatedLead}
          data-testid="financial-form-step"
          onSuccess={getLeads}
        />
      )}

      {step === 3 && createdLead && (
        <LegalFormStep
          usersList={usersList}
          step={step}
          setStep={setStep}
          createdLead={createdLead}
          setCreatedLead={setCreatedLead}
          data-testid="legal-form-step"
          getLeads={getLeads}
        />
      )}
    </FormContainer>
  );
};
