import React, { useEffect, useState } from 'react';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatCurrency,
  formatDate,
  formatProjectActivityType,
  TSortValues,
} from '../../utils';
import { Abbreviation, Badge, Button, Table } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon } from '../../icons';
import { Activity, Job, Project } from '../../backend/careo-api';
import { Accordion } from '../ui/accordion';
import { InfoCardRow } from '../ui/filters/tab-form.component';
import { useModal } from '../../contexts/side-modal.context';
import { AddActivityForm } from './add-activity-form';
import { AddRoleActivityForm } from './add-role-activity-form';
import { UpdateRoleActivityForm } from './update-role-activity-form';
import { useConfirm } from '../../contexts/confirm-modal.context';
import { toast } from 'react-toastify';
import { UpdateActivityForm } from './update-activity-form';
import { useLocation } from 'react-router-dom';

type ProjectDetailsActivitiesProps = {
  project: Project;
  getProjectDetails: () => void;
};

type SortState = {
  key: string;
  value: TSortValues;
};

export const ProjectDetailsActivities = ({
  project,
  getProjectDetails,
}: ProjectDetailsActivitiesProps) => {
  const [sort, setSort] = useState<SortState>({ key: '', value: '' });

  const { openModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const addNewActivity = () => {
    openModal({
      title: 'Add New Activity',
      component: (
        <AddActivityForm
          selectedProject={project}
          getProjectDetails={getProjectDetails}
        />
      ),
    });
  };

  const updateActivity = (activity: Activity) => {
    openModal({
      title: 'Update Activity',
      component: (
        <UpdateActivityForm
          selectedProject={project}
          selectedActivity={activity}
          getProjectDetails={getProjectDetails}
        />
      ),
    });
  };

  const addNewRoleActivity = (activity: Activity) => {
    openModal({
      title: 'Add New Role',
      component: (
        <AddRoleActivityForm
          selectedProject={project}
          selectedActivity={activity}
          getProjectDetails={getProjectDetails}
        />
      ),
    });
  };

  const updateRoleActivity = (role: Job, activity: Activity) => {
    openModal({
      title: 'Update Role',
      component: (
        <UpdateRoleActivityForm
          activity={activity}
          selectedRole={role}
          getProjectDetails={getProjectDetails}
        />
      ),
    });
  };

  const deleteRole = async (activity: Activity, role: Job) => {
    await AxiosInstance.projects
      .projectsControllerDeleteRoleInActivity(
        project._id,
        activity._id,
        role._id,
      )
      .then(() => {
        toast.success('Role Removed successfully');
        getProjectDetails();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteActivity = async (activity: Activity) => {
    await AxiosInstance.projects
      .projectsControllerDeleteActivity(project._id, activity._id)
      .then(() => {
        toast.success('Role Removed successfully');
        getProjectDetails();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onDeleteConfirmRole = (activity: Activity, role: Job) => {
    openConfirm({
      title: 'Delete Role',
      component: (
        <>
          Do you want to delete <b>this role</b> ?
        </>
      ),
      onConfirm: () => deleteRole(activity, role),
    });
  };

  const onDeleteConfirmActivity = (activity: Activity) => {
    openConfirm({
      title: 'Delete Activity',
      component: (
        <>
          Do you want to delete <b>this activity</b> ?
        </>
      ),
      onConfirm: () => deleteActivity(activity),
    });
  };
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activityId = searchParams.get('activity');
    const roleId = searchParams.get('role');
    console.log({ selectedActivity: activityId });

    if (activityId) {
      const _activity = project.activities.find((i) => i._id === activityId);
      if (roleId) {
        const _role = _activity?.roles.find((i) => i._id === roleId);

        if (_role && _activity) updateRoleActivity(_role, _activity);
      } else if (_activity) updateActivity(_activity);
    }
  }, [location]);

  return (
    <div
      className="info-card accordion"
      id="activities-accordion"
      data-testid="activities-accordion"
    >
      {!!project.activities?.length ? (
        <>
          {project.activities.map((activity) => (
            <Accordion
              title={`${activity.subSpecialty} - ${activity.activityType}`}
              className="primary-item"
              key={`activity-${activity._id}`}
              data-testid={`accordion-item-${activity._id}`}
              isOpenByDefault={true}
              onClickEdit={() => updateActivity(activity)}
              onClickDelete={() => onDeleteConfirmActivity(activity)}
            >
              {/* Overview Section */}
              <Accordion
                title="Overview"
                className="secondary-item"
                key={`overview-${activity._id}`}
                isOpenByDefault={true}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="info-card-content row">
                      <InfoCardRow
                        title="Specialty"
                        data={project.specialty}
                        data-testid={`specialty-${activity._id}`}
                      />
                      <InfoCardRow
                        title="Sub Specialty"
                        data={activity.subSpecialty}
                        data-testid={`specialty-${activity._id}`}
                      />
                      <InfoCardRow
                        title="Activity Start Date"
                        data={formatDate(activity.startDate)}
                        data-testid={`startDate-${activity._id}`}
                      />
                      <InfoCardRow
                        title="Activity End Date"
                        data={formatDate(activity.endDate)}
                        data-testid={`endDate-${activity._id}`}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-card-content row">
                      <InfoCardRow
                        title="Activity Type"
                        data={activity.activityType}
                        data-testid={`activityType-${activity._id}`}
                      />
                      <InfoCardRow
                        title="Activity Contact"
                        data={
                          <Badge type="neutral">
                            <Abbreviation>
                              {
                                activity?.clientDepartment
                                  ?.firstNameContact?.[0]
                              }
                              {activity?.clientDepartment?.lastNameContact?.[0]}
                            </Abbreviation>
                            {`${activity.clientDepartment?.firstNameContact} ${activity.clientDepartment?.lastNameContact}`}
                          </Badge>
                        }
                        data-testid={`contact-${activity._id}`}
                      />
                    </div>
                  </div>
                </div>
              </Accordion>

              {/* Rates Section */}
              <Accordion
                title="Charges"
                className="secondary-item"
                key={`rates-${activity._id}`}
                isOpenByDefault={true}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="info-card-content row">
                      <InfoCardRow
                        title="Type"
                        data={activity.rateType}
                        data-testid={`rateType-${activity._id}`}
                      />
                      <InfoCardRow
                        title="Charge"
                        data={formatCurrency(activity.charge)}
                        data-testid={`rate-${activity._id}`}
                      />
                      <InfoCardRow
                        title="Template"
                        data={formatProjectActivityType(
                          activity.template,
                          activity.rateType,
                        )}
                        data-testid={`template-${activity._id}`}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-card-content row">
                      <InfoCardRow
                        title="BCV Charge"
                        data={formatCurrency(activity.bcvCharge)}
                        data-testid={`bcv-${activity._id}`}
                      />

                      <InfoCardRow
                        title="BCV Unit"
                        data={formatProjectActivityType(
                          activity.bcvUnit,
                          activity.rateType,
                        )}
                        data-testid={`bcv-${activity._id}`}
                      />
                    </div>
                  </div>
                </div>
              </Accordion>

              {/* Roles Section */}
              <Accordion
                title="Roles"
                className="secondary-item"
                key={`placements-${activity._id}`}
                isOpenByDefault={true}
              >
                <div
                  className="data-table-container"
                  data-testid="data-table-container"
                >
                  <Table data-testid={`roles-table-${activity._id}`}>
                    <thead>
                      <tr>
                        {[
                          'Job Title',
                          'Grade',
                          'Start Time',
                          'End Time',
                          'Rate',
                          'Accommodation',
                          'Travel',
                          'Food',
                          'Other Expenses',
                          '',
                        ].map((header, index) => (
                          <th
                            key={index}
                            onClick={() => {}}
                            data-testid={`${header.toLowerCase()}-sort-header`}
                          >
                            <div>
                              <label>{header}</label>
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {activity.roles?.length > 0 ? (
                        activity.roles.map((role, roleIndex) => (
                          <tr
                            key={roleIndex}
                            data-testid={`role-row-${roleIndex}`}
                          >
                            <td>{role.level}</td>
                            <td>{role.grade}</td>
                            <td>08:30</td>
                            <td>16:10</td>
                            <td>{formatCurrency(role.rate)}</td>

                            <td>
                              {formatCurrency(role.expenses?.accommodation)}
                            </td>
                            <td>{formatCurrency(role.expenses?.travel)}</td>

                            <td>{formatCurrency(role.expenses?.food)}</td>
                            <td>{formatCurrency(role.expenses?.other)}</td>
                            <td>
                              <div
                                className="action-item"
                                data-testid={`role-action-${roleIndex}`}
                              >
                                <div
                                  className="edit-icon"
                                  onClick={() =>
                                    updateRoleActivity(role, activity)
                                  }
                                  data-testid={`view-icon-${roleIndex}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() =>
                                    onDeleteConfirmRole(activity, role)
                                  }
                                  data-testid={`delete-icon-${roleIndex}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={100}
                            className="text-center"
                            data-testid="no-item-found"
                          >
                            No item found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <Button
                  type="success"
                  onClick={() => addNewRoleActivity(activity)}
                  data-testid={`add-role-button-${activity._id}`}
                  variant="outlined"
                >
                  <PlusIcon /> Add new role
                </Button>
              </Accordion>
            </Accordion>
          ))}
        </>
      ) : (
        <div className="text-center">No activities found.</div>
      )}

      <Button
        type="success"
        onClick={() => addNewActivity()}
        data-testid="add-activity-button"
        variant="outlined"
      >
        <PlusIcon /> Add new activity
      </Button>
    </div>
  );
};
