import React, { useEffect, useMemo, useState } from 'react';
import {
  TimesheetActionContainer,
  TimesheetContainer,
} from './timesheet.style';
import { AppIcon, DeleteIcon } from '../../icons';
import { Button, FieldText, Table } from '../../components';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatDate,
  formatProjectActivityType,
} from '../../utils';
import {
  EProjectActivityRateType,
  ETimesheetStatus,
  Timesheet,
  Timing,
} from '../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Define the schema for validation
const schema = yup.object().shape({
  timings: yup
    .array()
    .of(
      yup.object().shape({
        startTime: yup.string(),
        endTime: yup.string(),

        breakTime: yup.number(),
        chargingUnitCount: yup
          .number()
          .required('Field is  required')
          .typeError('Must be number')
          .min(0, 'Must be a positive number'),

        accommodationExpensesByCandidate: yup
          .number()
          .required('Field is  required')
          .typeError('Must be number')
          .min(0, 'Must be a positive number'),
        travelExpenses: yup
          .number()
          .required('Field is  required')
          .typeError('Must be number')
          .min(0, 'Must be a positive number'),
        foodExpenses: yup
          .number()
          .required('Field is  required')
          .typeError('Must be number')
          .min(0, 'Must be a positive number'),
        otherExpenses: yup
          .number()
          .required('Field is  required')
          .typeError('Must be number')
          .min(0, 'Must be a positive number'),

        date: yup.string().required('Date is required'),
      }),
    )
    .required('Timings are required'),
});

const TimesheetCandidatePage = () => {
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<{
    timings: Omit<Timing, 'accommodationExpensesByBusiness'>[];
  }>({
    resolver: yupResolver(schema),
  });
  const timings = watch('timings');

  const [timesheet, setTimesheet] = useState<Timesheet | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log({ activity: timesheet?.activity });
  }, [timesheet]);

  const totalOfWorkedUnits: number = useMemo(() => {
    return timings?.reduce(
      (total, timing) => total + (timing.chargingUnitCount ?? 0),
      0,
    );
  }, [timings]);

  const getTimesheetDetails = async () => {
    try {
      const result = (
        await AxiosInstance.timesheetMember.timesheetMemberControllerFindOneByToken(
          token!,
        )
      ).data;
      if (result.status === ETimesheetStatus.Rejected) {
        result.status = ETimesheetStatus.Generated;
      }

      reset({
        timings: [...result.timings],
      });
      setTimesheet(result);
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onSubmit = async (data: any) => {
    AxiosInstance.timesheetMember
      .timesheetMemberControllerAddTimingsByCandidateToken(token!, {
        timings: data.timings,
      })
      .then(() => {
        toast.success('Timesheet sent successfully');
        getTimesheetDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickRemoveDay = (index: number, dayName: string) => {
    const result = timings.filter((el, index2) => index2 !== index);
    reset({
      ...{ timings: result },
    });
    toast.warning(`${dayName} has been deleted`);
  };

  useEffect(() => {
    getTimesheetDetails();
  }, []);

  if (isLoading) {
    return <></>;
  }

  if (!timesheet) {
    return <>Timesheet not found</>;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TimesheetContainer
          className="container"
          id="container"
          data-testid="timesheet-container"
        >
          <div className="logo-container" data-testid="logo-container">
            <AppIcon />
          </div>
          {timesheet.status === 'Rejected' && (
            <div className="title" data-testid="timesheet-rejected">
              Timesheet rejected
            </div>
          )}
          <div className="title" data-testid="timesheet-status">
            {timesheet.status === 'Generated'
              ? 'Please fill in your Agency Locum Weekly timesheet'
              : 'Timesheet is already submitted'}
          </div>
          <div className="title" data-testid="timesheet-title">
            Timesheet
          </div>
          <FieldText
            label="Placement"
            value={
              timesheet?.placement.job?.client?.clientName +
              ' - ' +
              timesheet?.placement?.job.specialty
            }
            disabled
            data-testid="placement-field-text"
          />
          <div className="subtitle" data-testid="personal-details-title">
            Personal Details
          </div>
          <div
            className="personal-info-container"
            data-testid="personal-info-container"
          >
            <div className="personal-name" data-testid="personal-name">
              {timesheet?.placement?.candidate?.title}{' '}
              {timesheet?.placement?.candidate?.firstName}{' '}
              {timesheet?.placement?.candidate?.lastName}
            </div>
            <div className="row" data-testid="personal-info-row">
              <div className="col-md-6 row">
                <div
                  className="info-item-title col-5"
                  data-testid="specialty-title"
                >
                  Specialty
                </div>
                <div
                  className="info-item-data col-7"
                  data-testid="specialty-data"
                >
                  {timesheet?.placement?.candidate?.designation || '-'}
                </div>
              </div>
              <div className="col-md-6 row">
                <div
                  className="info-item-title col-5"
                  data-testid="department-title"
                >
                  Department
                </div>
                <div
                  className="info-item-data col-7"
                  data-testid="department-data"
                >
                  {timesheet?.placement?.candidate?.departments?.join(',') ||
                    '-'}
                </div>
              </div>
              <div className="col-md-6 row">
                <div
                  className="info-item-title col-5"
                  data-testid="week-commencing-title"
                >
                  Week Commencing
                </div>
                <div
                  className="info-item-data col-7"
                  data-testid="week-commencing-data"
                >
                  {formatDate(timesheet?.placement?.availableFrom ?? '')}
                </div>
              </div>
              <div className="col-md-6 row">
                <div className="info-item-title col-5" data-testid="team-title">
                  Team
                </div>
                <div className="info-item-data col-7" data-testid="team-data">
                  {timesheet?.placement?.job.specialty}
                </div>
              </div>
              <div className="col-md-6 row">
                <div
                  className="info-item-title col-5"
                  data-testid="email-title"
                >
                  Email Address
                </div>
                <div className="info-item-data col-7" data-testid="email-data">
                  {timesheet?.placement?.candidate?.email}
                </div>
              </div>
              <div className="col-md-6 row">
                <div
                  className="info-item-title col-5"
                  data-testid="grade-title"
                >
                  Grade
                </div>
                <div className="info-item-data col-7" data-testid="grade-data">
                  {timesheet?.placement?.job?.grade}
                </div>
              </div>
            </div>
          </div>
          <div className="subtitle" data-testid="update-timesheet-title">
            Update Timesheet below
          </div>
          {/* <div className="text" data-testid="breaks-info-text">
            Breaks Must be recorded in the same detail as the Assignment
            confirmation
          </div> */}
          <div
            className="accordion"
            id="accordionPanelsStayOpenExample"
            data-testid="accordion-container"
          >
            {timings?.map((el, index) => {
              const dayName = `${new Date(el.date).toLocaleDateString('en-US', {
                weekday: 'long',
              })}- ${formatDate(el.date)}`;
              return (
                <div
                  className="accordion-item"
                  key={index}
                  data-testid={`accordion-item-${index}`}
                >
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingOne"
                  >
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-${index}`}
                      aria-expanded="true"
                      aria-controls={`panelsStayOpen-${index}`}
                      data-testid={`accordion-button-${index}`}
                    >
                      {dayName}
                    </button>
                  </h2>
                  <div
                    id={`panelsStayOpen-${index}`}
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                    data-testid={`accordion-collapse-${index}`}
                  >
                    <div
                      className="accordion-body"
                      data-testid={`accordion-body-${index}`}
                    >
                      <div className="info-card-content row mb-5">
                        <Table data-testid={`timesheet-table-${index}`}>
                          <thead>
                            <tr>
                              {/* <th>Working Day</th>
                              <th>Time</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {timesheet.activity?.rateType ===
                                EProjectActivityRateType.PerHour
                                  ? 'Worked Hours'
                                  : timesheet.activity?.rateType ===
                                      EProjectActivityRateType.PerSession
                                    ? 'Worked Sessions'
                                    : 'Treated Patients'}
                              </td>
                              <td>
                                <FieldText
                                  type="number"
                                  register={register(
                                    `timings.${index}.chargingUnitCount`,
                                  )}
                                  error={
                                    errors.timings?.[index]?.chargingUnitCount
                                  }
                                  disabled={timesheet.status !== 'Generated'}
                                  data-testid={`charging-unit-count-${index}`}
                                  min={0}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Accommodation</td>
                              <td>
                                <FieldText
                                  type="currency"
                                  register={register(
                                    `timings.${index}.accommodationExpensesByCandidate`,
                                  )}
                                  error={
                                    errors.timings?.[index]
                                      ?.accommodationExpensesByCandidate
                                  }
                                  disabled={timesheet.status !== 'Generated'}
                                  data-testid={`accommodation-expenses-by-candidate-${index}`}
                                  min={0}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Travel</td>
                              <td>
                                <FieldText
                                  type="currency"
                                  register={register(
                                    `timings.${index}.travelExpenses`,
                                  )}
                                  error={
                                    errors.timings?.[index]?.travelExpenses
                                  }
                                  disabled={timesheet.status !== 'Generated'}
                                  data-testid={`travel-expenses-${index}`}
                                  min={0}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Food</td>
                              <td>
                                <FieldText
                                  type="currency"
                                  register={register(
                                    `timings.${index}.foodExpenses`,
                                  )}
                                  error={errors.timings?.[index]?.foodExpenses}
                                  disabled={timesheet.status !== 'Generated'}
                                  data-testid={`food-expenses-${index}`}
                                  min={0}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Other Expenses</td>
                              <td>
                                <FieldText
                                  type="currency"
                                  register={register(
                                    `timings.${index}.otherExpenses`,
                                  )}
                                  error={errors.timings?.[index]?.otherExpenses}
                                  disabled={timesheet.status !== 'Generated'}
                                  data-testid={`other-expenses-${index}`}
                                  min={0}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-end">
                          {timesheet.status === 'Generated' &&
                            timings.length > 1 && (
                              <Button
                                type="danger"
                                onClick={() => onClickRemoveDay(index, dayName)}
                                data-testid={`remove-day-button-${index}`}
                              >
                                <DeleteIcon />
                                Remove this day
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </TimesheetContainer>

        <TimesheetActionContainer
          className="container"
          data-testid="timesheet-action-container"
        >
          <div className="left-container" data-testid="total-working-time">
            {timesheet.activity?.rateType === EProjectActivityRateType.PerHour
              ? 'Total Number Of Worked Hours'
              : timesheet.activity?.rateType ===
                  EProjectActivityRateType.PerSession
                ? 'Total Number Of Worked Sessions'
                : 'Total Number Of Treated Patients'}{' '}
            :{' '}
            <label>
              {formatProjectActivityType(
                totalOfWorkedUnits,
                timesheet.activity?.rateType,
              )}
            </label>
          </div>
          {timesheet.status === 'Generated' && (
            <div
              className="right-container"
              data-testid="save-button-container"
            >
              <Button
                type="primary"
                data-testid="save-button"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </div>
          )}
        </TimesheetActionContainer>
      </form>
    </>
  );
};

export default TimesheetCandidatePage;
