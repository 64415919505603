import React, { useEffect, useState } from 'react';
import { CallIcon, LeftArrowIcon, MessageIcon } from '../../../icons';
import {
  AddressDetails,
  ATag,
  CardContainer,
  DocumentsListComponent,
  DragDropUpload,
  TitlePage,
  UserIcon,
  ClientDepartments,
  TabPageContainer,
} from '../../../components';
import { ClientDetailsContainer } from './client-details.style';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosInstance, ERoute, uploadClientFiles } from '../../../utils';
import { Client, HostedFile } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { ChargesRatesTab } from '../../../components/client/charges-rates-tab.components';
import { useModal } from '../../../contexts/side-modal.context';
import { useConfirm } from '../../../contexts/confirm-modal.context';
import NoteTab from '../../../components/note/note-tab.component';
import { NoteForm } from '../../../components/note/note-form.component';

export enum EClientTabs {
  GENERAL = 'general',
  CONTACT = 'contact',
  PAYROLL = 'payroll',
  DOCUMENTS = 'documents',
  DEPARTMENTS = 'departments',
  RATES = 'rates',
  NOTES = 'notes',
}

const tabs = [
  EClientTabs.GENERAL,
  EClientTabs.CONTACT,
  EClientTabs.DEPARTMENTS,
  EClientTabs.PAYROLL,
  EClientTabs.DOCUMENTS,
  EClientTabs.RATES,
  EClientTabs.NOTES,
];

export const ClientDetailsPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const { openModal, closeModal } = useModal();
  const { closeConfirm } = useConfirm();

  const [selectedTab, setSelectedTab] = useState(EClientTabs.GENERAL);
  const [client, setClient] = useState<Client | null>(null);
  const [clientDocuments, setClientDocuments] = useState<HostedFile[]>([]);

  const submitRequest = (note: string) => {
    return AxiosInstance.clients.clientsControllerCreateNoteClient(id!, {
      text: note,
    });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  const getClientDetails = () => {
    AxiosInstance.clients
      .clientsControllerGetClient(id!)
      .then((response) => {
        setClient(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getClientDocuments = () => {
    AxiosInstance.files
      .filesControllerFindClientDocs(id!)
      .then((response) => {
        setClientDocuments(response.data.items);
      })
      .catch(() => {
        toast.error('Failed to get client documents');
      });
  };

  const deleteNote = (noteId: string) => {
    AxiosInstance.clients
      .clientsControllerDeleteNoteClient(id!, noteId)
      .then(() => {
        getClientDetails();
        closeConfirm();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickCreate = () => {
    openModal({
      title: 'New Note',
      component: (
        <NoteForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getClientDetails();
            closeModal();
          }}
          submitRequest={submitRequest}
        />
      ),
    });
  };

  const handleFileChange = async (files: File[]) => {
    await uploadClientFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getClientDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = async (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getClientDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as EClientTabs) || EClientTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {
    getClientDetails();
    getClientDocuments();
  }, []);

  if (!client) {
    return <></>;
  }

  return (
    <>
      <ClientDetailsContainer>
        <div className="client-header" data-testid="client-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon />
          </div>
          <TitlePage data-testid="client-title">Detail Client</TitlePage>
        </div>
        <CardContainer
          className="page-summary-container"
          data-testid="page-summary-container"
        >
          <div className="module-name" data-testid="client-module-name">
            <UserIcon
              firstName={client.firstName}
              lastName={client.lastName}
              entity="client"
              data-testid="client-user-icon"
            />
            <div className="module-info" data-testid="client-module-info">
              <div className="name" data-testid="client-name">
                {client.firstName} {client.lastName}
              </div>
              <div className="availability" data-testid="client-availability">
                {client.address?.city}
              </div>
            </div>
          </div>
          <div className="module-contact" data-testid="client-phone">
            <CallIcon />
            <div>{client.phoneNumber}</div>
          </div>
          <div className="module-contact" data-testid="client-email">
            <MessageIcon />
            <div>{client.email}</div>
          </div>
        </CardContainer>
        <CardContainer
          className="client-information-container"
          data-testid="client-information-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${
                    el === selectedTab && 'active'
                  }`}
                  onClick={() => navigate(`/${ERoute.CLIENTS}/${id}/${el}`)}
                  data-testid={`tab-item-${el}`}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === EClientTabs.GENERAL && (
                <>
                  <div
                    className="info-card"
                    data-testid="client-general-info-card"
                  >
                    <div
                      className="info-card-title"
                      data-testid="client-info-title"
                    >
                      Client Info
                    </div>
                    <hr />
                    <div
                      className="info-card-content row"
                      data-testid="client-general-info-content"
                    >
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="client-name-title"
                        >
                          Client Name
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="client-name-data"
                        >
                          {client.clientName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="client-trust-title"
                        >
                          Trust
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="client-trust-data"
                        >
                          {client?.trust?.name || '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="client-line-manager-title"
                        >
                          Line manager
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="client-line-manager-data"
                        >
                          {client.user.firstName} {client.user.lastName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="client-email-title"
                        >
                          Email Address
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="client-email-data"
                        >
                          {client.email}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="client-phone-number-title"
                        >
                          Phone Number
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="client-phone-number-data"
                        >
                          {client.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddressDetails
                    address={client.address}
                    data-testid="client-address-details"
                  />
                </>
              )}
              {selectedTab === EClientTabs.CONTACT && (
                <div
                  className="info-card"
                  data-testid="client-contact-info-card"
                >
                  <div
                    className="info-card-title"
                    data-testid="contact-info-title"
                  >
                    Contact Details
                  </div>
                  <hr />
                  <div
                    className="info-card-content row"
                    data-testid="contact-info-content"
                  >
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-title-title"
                      >
                        Title
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-title-data"
                      >
                        {client?.title}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-first-name-title"
                      >
                        First Name
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-first-name-data"
                      >
                        {client?.firstName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-last-name-title"
                      >
                        Last Name
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-last-name-data"
                      >
                        {client?.lastName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-job-title-title"
                      >
                        Job Title
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-job-title-data"
                      >
                        {client?.jobTitle}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-email-title"
                      >
                        Email Address
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-email-data"
                      >
                        {client?.email}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-mobile-number-title"
                      >
                        Mobile Number
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-mobile-number-data"
                      >
                        {client?.phoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-work-phone-number-title"
                      >
                        Work Phone Number
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-work-phone-number-data"
                      >
                        {client?.workPhoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-website-title"
                      >
                        Website
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-website-data"
                      >
                        {client?.website}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-fax-number-title"
                      >
                        Fax Number
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-fax-number-data"
                      >
                        {client?.faxNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="contact-department-title"
                      >
                        Department
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="contact-department-data"
                      >
                        {client?.address?.county}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EClientTabs.PAYROLL && (
                <div
                  className="info-card"
                  data-testid="client-payroll-info-card"
                >
                  <div
                    className="info-card-title"
                    data-testid="payroll-info-title"
                  >
                    Bank Account
                  </div>
                  <hr />
                  <div
                    className="info-card-content row"
                    data-testid="payroll-info-content"
                  >
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="payroll-bank-name-title"
                      >
                        Bank Name
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="payroll-bank-name-data"
                      >
                        {client?.bank?.name}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="payroll-account-name-title"
                      >
                        Account Name
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="payroll-account-name-data"
                      >
                        {client?.bank?.accountName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="payroll-branch-title"
                      >
                        Branch
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="payroll-branch-data"
                      >
                        {client?.address?.city}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="payroll-account-number-title"
                      >
                        Account Number
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="payroll-account-number-data"
                      >
                        {client?.bank?.accountNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="payroll-type-title"
                      >
                        Type
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="payroll-type-data"
                      >
                        -
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div
                        className="info-item-title col-4"
                        data-testid="payroll-sort-code-title"
                      >
                        Sort Code
                      </div>
                      <div
                        className="info-item-data col-8"
                        data-testid="payroll-sort-code-data"
                      >
                        {client?.bank?.sortNumber}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EClientTabs.DOCUMENTS && (
                <>
                  <div
                    className="info-card"
                    data-testid="client-documents-info-card"
                  >
                    <div
                      className="info-card-title"
                      data-testid="documents-info-title"
                    >
                      Client Documents
                    </div>
                    <hr />
                    <DocumentsListComponent
                      documents={clientDocuments}
                      onClickDelete={onClickDelete}
                      getDocuments={getClientDocuments}
                      data-testid="documents-list"
                    />
                  </div>
                  <div
                    className="info-card"
                    data-testid="client-documents-upload"
                  >
                    <DragDropUpload onUpload={handleFileChange} />
                  </div>
                </>
              )}
              {selectedTab === EClientTabs.DEPARTMENTS && (
                <ClientDepartments
                  client={client}
                  getClientDetails={getClientDetails}
                  data-testid="client-departments"
                />
              )}
              {selectedTab === EClientTabs.RATES && (
                <ChargesRatesTab
                  client={client}
                  getClientDetails={getClientDetails}
                  data-testid="client-rates"
                />
              )}
              {selectedTab === EClientTabs.NOTES && (
                <NoteTab
                  notes={client.notes ?? []}
                  onCreateNote={onClickCreate}
                  onDeleteNote={deleteNote}
                />
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </ClientDetailsContainer>
    </>
  );
};
